/* tslint:disable */
/* eslint-disable */
/**
 * Mjøsen Skog skogbruksleder API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { ActionModelsLibrarySBLNoteSblAppCreateNoteSblAppCreateNoteSblAppInput } from '../model';
// @ts-ignore
import { ActionModelsLibrarySBLNoteSblAppCreateNoteSblAppCreateNoteSblAppOutput } from '../model';
// @ts-ignore
import { ActionModelsLibrarySBLNoteSblAppListNoteSblAppListNoteSblAppOutput } from '../model';
// @ts-ignore
import { ActionModelsLibrarySBLNoteSblAppListNoteSblAppNoteSblAppItem } from '../model';
// @ts-ignore
import { ActionModelsLibrarySBLNoteSblAppUpdateNoteSblAppUpdateNoteSblAppInput } from '../model';
// @ts-ignore
import { ActionModelsLibrarySBLNoteSblAppUpdateNoteSblAppUpdateNoteSblAppOutput } from '../model';
// @ts-ignore
import { GmModelLibraryModelsDocumentModel } from '../model';
/**
 * NotesAndFilesApi - axios parameter creator
 * @export
 */
export const NotesAndFilesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create note
         * @param {ActionModelsLibrarySBLNoteSblAppCreateNoteSblAppCreateNoteSblAppInput} [actionModelsLibrarySBLNoteSblAppCreateNoteSblAppCreateNoteSblAppInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notesAndFilesCreateNote: async (actionModelsLibrarySBLNoteSblAppCreateNoteSblAppCreateNoteSblAppInput?: ActionModelsLibrarySBLNoteSblAppCreateNoteSblAppCreateNoteSblAppInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/sbl/notes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(actionModelsLibrarySBLNoteSblAppCreateNoteSblAppCreateNoteSblAppInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete document from CRM
         * @param {string} contractId 
         * @param {string} docId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notesAndFilesDeleteCrmDocument: async (contractId: string, docId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('notesAndFilesDeleteCrmDocument', 'contractId', contractId)
            // verify required parameter 'docId' is not null or undefined
            assertParamExists('notesAndFilesDeleteCrmDocument', 'docId', docId)
            const localVarPath = `/v1/sbl/files/{contractId}/document/{docId}/crm`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)))
                .replace(`{${"docId"}}`, encodeURIComponent(String(docId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete note
         * @param {string} noteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notesAndFilesDeleteNote: async (noteId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'noteId' is not null or undefined
            assertParamExists('notesAndFilesDeleteNote', 'noteId', noteId)
            const localVarPath = `/v1/sbl/notes/{noteId}`
                .replace(`{${"noteId"}}`, encodeURIComponent(String(noteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete document from Sharepoint
         * @param {string} contractId 
         * @param {string} docId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notesAndFilesDeleteSharePointDocument: async (contractId: string, docId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('notesAndFilesDeleteSharePointDocument', 'contractId', contractId)
            // verify required parameter 'docId' is not null or undefined
            assertParamExists('notesAndFilesDeleteSharePointDocument', 'docId', docId)
            const localVarPath = `/v1/sbl/files/{contractId}/document/{docId}/sharepoint`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)))
                .replace(`{${"docId"}}`, encodeURIComponent(String(docId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get document from CRM
         * @param {string} contractId 
         * @param {string} docId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notesAndFilesGetCrmDocument: async (contractId: string, docId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('notesAndFilesGetCrmDocument', 'contractId', contractId)
            // verify required parameter 'docId' is not null or undefined
            assertParamExists('notesAndFilesGetCrmDocument', 'docId', docId)
            const localVarPath = `/v1/sbl/files/{contractId}/document/{docId}/crm`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)))
                .replace(`{${"docId"}}`, encodeURIComponent(String(docId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get note
         * @param {string} noteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notesAndFilesGetNote: async (noteId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'noteId' is not null or undefined
            assertParamExists('notesAndFilesGetNote', 'noteId', noteId)
            const localVarPath = `/v1/sbl/notes/{noteId}`
                .replace(`{${"noteId"}}`, encodeURIComponent(String(noteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all notes for the forest-manger
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notesAndFilesGetNotes: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/sbl/notes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List files  - Sharepoint files from contract  - CRM (Annotation) files from production-order
         * @param {string} contractId 
         * @param {string} productionOrderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notesAndFilesGetProductionOrderDocuments: async (contractId: string, productionOrderId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('notesAndFilesGetProductionOrderDocuments', 'contractId', contractId)
            // verify required parameter 'productionOrderId' is not null or undefined
            assertParamExists('notesAndFilesGetProductionOrderDocuments', 'productionOrderId', productionOrderId)
            const localVarPath = `/v1/sbl/files/{contractId}/{productionOrderId}`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)))
                .replace(`{${"productionOrderId"}}`, encodeURIComponent(String(productionOrderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get document from Sharepoint
         * @param {string} contractId 
         * @param {string} docId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notesAndFilesGetSharePointDocument: async (contractId: string, docId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('notesAndFilesGetSharePointDocument', 'contractId', contractId)
            // verify required parameter 'docId' is not null or undefined
            assertParamExists('notesAndFilesGetSharePointDocument', 'docId', docId)
            const localVarPath = `/v1/sbl/files/{contractId}/document/{docId}/sharepoint`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)))
                .replace(`{${"docId"}}`, encodeURIComponent(String(docId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Share note with another SBL
         * @param {string} noteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notesAndFilesShareNote: async (noteId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'noteId' is not null or undefined
            assertParamExists('notesAndFilesShareNote', 'noteId', noteId)
            const localVarPath = `/v1/sbl/notes/{noteId}`
                .replace(`{${"noteId"}}`, encodeURIComponent(String(noteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update note
         * @param {ActionModelsLibrarySBLNoteSblAppUpdateNoteSblAppUpdateNoteSblAppInput} [actionModelsLibrarySBLNoteSblAppUpdateNoteSblAppUpdateNoteSblAppInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notesAndFilesUpdateNote: async (actionModelsLibrarySBLNoteSblAppUpdateNoteSblAppUpdateNoteSblAppInput?: ActionModelsLibrarySBLNoteSblAppUpdateNoteSblAppUpdateNoteSblAppInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/sbl/notes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(actionModelsLibrarySBLNoteSblAppUpdateNoteSblAppUpdateNoteSblAppInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload document to CRM
         * @param {string} contractId 
         * @param {string} productionOrderId 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notesAndFilesUploadCrmDocument: async (contractId: string, productionOrderId: string, file?: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('notesAndFilesUploadCrmDocument', 'contractId', contractId)
            // verify required parameter 'productionOrderId' is not null or undefined
            assertParamExists('notesAndFilesUploadCrmDocument', 'productionOrderId', productionOrderId)
            const localVarPath = `/v1/sbl/files/{contractId}/{productionOrderId}/document/crm`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)))
                .replace(`{${"productionOrderId"}}`, encodeURIComponent(String(productionOrderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload document to Sharepoint
         * @param {string} contractId 
         * @param {string} productionOrderId 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notesAndFilesUploadSharePointDocument: async (contractId: string, productionOrderId: string, file?: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('notesAndFilesUploadSharePointDocument', 'contractId', contractId)
            // verify required parameter 'productionOrderId' is not null or undefined
            assertParamExists('notesAndFilesUploadSharePointDocument', 'productionOrderId', productionOrderId)
            const localVarPath = `/v1/sbl/files/{contractId}/{productionOrderId}/document/sharepoint`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)))
                .replace(`{${"productionOrderId"}}`, encodeURIComponent(String(productionOrderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotesAndFilesApi - functional programming interface
 * @export
 */
export const NotesAndFilesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotesAndFilesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create note
         * @param {ActionModelsLibrarySBLNoteSblAppCreateNoteSblAppCreateNoteSblAppInput} [actionModelsLibrarySBLNoteSblAppCreateNoteSblAppCreateNoteSblAppInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notesAndFilesCreateNote(actionModelsLibrarySBLNoteSblAppCreateNoteSblAppCreateNoteSblAppInput?: ActionModelsLibrarySBLNoteSblAppCreateNoteSblAppCreateNoteSblAppInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionModelsLibrarySBLNoteSblAppCreateNoteSblAppCreateNoteSblAppOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notesAndFilesCreateNote(actionModelsLibrarySBLNoteSblAppCreateNoteSblAppCreateNoteSblAppInput, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['NotesAndFilesApi.notesAndFilesCreateNote']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Delete document from CRM
         * @param {string} contractId 
         * @param {string} docId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notesAndFilesDeleteCrmDocument(contractId: string, docId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notesAndFilesDeleteCrmDocument(contractId, docId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['NotesAndFilesApi.notesAndFilesDeleteCrmDocument']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Delete note
         * @param {string} noteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notesAndFilesDeleteNote(noteId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notesAndFilesDeleteNote(noteId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['NotesAndFilesApi.notesAndFilesDeleteNote']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Delete document from Sharepoint
         * @param {string} contractId 
         * @param {string} docId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notesAndFilesDeleteSharePointDocument(contractId: string, docId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notesAndFilesDeleteSharePointDocument(contractId, docId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['NotesAndFilesApi.notesAndFilesDeleteSharePointDocument']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get document from CRM
         * @param {string} contractId 
         * @param {string} docId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notesAndFilesGetCrmDocument(contractId: string, docId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notesAndFilesGetCrmDocument(contractId, docId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['NotesAndFilesApi.notesAndFilesGetCrmDocument']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get note
         * @param {string} noteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notesAndFilesGetNote(noteId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionModelsLibrarySBLNoteSblAppListNoteSblAppNoteSblAppItem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notesAndFilesGetNote(noteId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['NotesAndFilesApi.notesAndFilesGetNote']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get all notes for the forest-manger
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notesAndFilesGetNotes(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionModelsLibrarySBLNoteSblAppListNoteSblAppListNoteSblAppOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notesAndFilesGetNotes(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['NotesAndFilesApi.notesAndFilesGetNotes']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary List files  - Sharepoint files from contract  - CRM (Annotation) files from production-order
         * @param {string} contractId 
         * @param {string} productionOrderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notesAndFilesGetProductionOrderDocuments(contractId: string, productionOrderId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GmModelLibraryModelsDocumentModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notesAndFilesGetProductionOrderDocuments(contractId, productionOrderId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['NotesAndFilesApi.notesAndFilesGetProductionOrderDocuments']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get document from Sharepoint
         * @param {string} contractId 
         * @param {string} docId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notesAndFilesGetSharePointDocument(contractId: string, docId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notesAndFilesGetSharePointDocument(contractId, docId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['NotesAndFilesApi.notesAndFilesGetSharePointDocument']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Share note with another SBL
         * @param {string} noteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notesAndFilesShareNote(noteId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notesAndFilesShareNote(noteId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['NotesAndFilesApi.notesAndFilesShareNote']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Update note
         * @param {ActionModelsLibrarySBLNoteSblAppUpdateNoteSblAppUpdateNoteSblAppInput} [actionModelsLibrarySBLNoteSblAppUpdateNoteSblAppUpdateNoteSblAppInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notesAndFilesUpdateNote(actionModelsLibrarySBLNoteSblAppUpdateNoteSblAppUpdateNoteSblAppInput?: ActionModelsLibrarySBLNoteSblAppUpdateNoteSblAppUpdateNoteSblAppInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionModelsLibrarySBLNoteSblAppUpdateNoteSblAppUpdateNoteSblAppOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notesAndFilesUpdateNote(actionModelsLibrarySBLNoteSblAppUpdateNoteSblAppUpdateNoteSblAppInput, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['NotesAndFilesApi.notesAndFilesUpdateNote']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Upload document to CRM
         * @param {string} contractId 
         * @param {string} productionOrderId 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notesAndFilesUploadCrmDocument(contractId: string, productionOrderId: string, file?: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GmModelLibraryModelsDocumentModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notesAndFilesUploadCrmDocument(contractId, productionOrderId, file, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['NotesAndFilesApi.notesAndFilesUploadCrmDocument']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Upload document to Sharepoint
         * @param {string} contractId 
         * @param {string} productionOrderId 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notesAndFilesUploadSharePointDocument(contractId: string, productionOrderId: string, file?: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GmModelLibraryModelsDocumentModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notesAndFilesUploadSharePointDocument(contractId, productionOrderId, file, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['NotesAndFilesApi.notesAndFilesUploadSharePointDocument']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * NotesAndFilesApi - factory interface
 * @export
 */
export const NotesAndFilesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotesAndFilesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create note
         * @param {ActionModelsLibrarySBLNoteSblAppCreateNoteSblAppCreateNoteSblAppInput} [actionModelsLibrarySBLNoteSblAppCreateNoteSblAppCreateNoteSblAppInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notesAndFilesCreateNote(actionModelsLibrarySBLNoteSblAppCreateNoteSblAppCreateNoteSblAppInput?: ActionModelsLibrarySBLNoteSblAppCreateNoteSblAppCreateNoteSblAppInput, options?: any): AxiosPromise<ActionModelsLibrarySBLNoteSblAppCreateNoteSblAppCreateNoteSblAppOutput> {
            return localVarFp.notesAndFilesCreateNote(actionModelsLibrarySBLNoteSblAppCreateNoteSblAppCreateNoteSblAppInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete document from CRM
         * @param {string} contractId 
         * @param {string} docId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notesAndFilesDeleteCrmDocument(contractId: string, docId: string, options?: any): AxiosPromise<void> {
            return localVarFp.notesAndFilesDeleteCrmDocument(contractId, docId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete note
         * @param {string} noteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notesAndFilesDeleteNote(noteId: string, options?: any): AxiosPromise<void> {
            return localVarFp.notesAndFilesDeleteNote(noteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete document from Sharepoint
         * @param {string} contractId 
         * @param {string} docId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notesAndFilesDeleteSharePointDocument(contractId: string, docId: string, options?: any): AxiosPromise<void> {
            return localVarFp.notesAndFilesDeleteSharePointDocument(contractId, docId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get document from CRM
         * @param {string} contractId 
         * @param {string} docId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notesAndFilesGetCrmDocument(contractId: string, docId: string, options?: any): AxiosPromise<void> {
            return localVarFp.notesAndFilesGetCrmDocument(contractId, docId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get note
         * @param {string} noteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notesAndFilesGetNote(noteId: string, options?: any): AxiosPromise<ActionModelsLibrarySBLNoteSblAppListNoteSblAppNoteSblAppItem> {
            return localVarFp.notesAndFilesGetNote(noteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all notes for the forest-manger
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notesAndFilesGetNotes(options?: any): AxiosPromise<ActionModelsLibrarySBLNoteSblAppListNoteSblAppListNoteSblAppOutput> {
            return localVarFp.notesAndFilesGetNotes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List files  - Sharepoint files from contract  - CRM (Annotation) files from production-order
         * @param {string} contractId 
         * @param {string} productionOrderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notesAndFilesGetProductionOrderDocuments(contractId: string, productionOrderId: string, options?: any): AxiosPromise<Array<GmModelLibraryModelsDocumentModel>> {
            return localVarFp.notesAndFilesGetProductionOrderDocuments(contractId, productionOrderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get document from Sharepoint
         * @param {string} contractId 
         * @param {string} docId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notesAndFilesGetSharePointDocument(contractId: string, docId: string, options?: any): AxiosPromise<void> {
            return localVarFp.notesAndFilesGetSharePointDocument(contractId, docId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Share note with another SBL
         * @param {string} noteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notesAndFilesShareNote(noteId: string, options?: any): AxiosPromise<void> {
            return localVarFp.notesAndFilesShareNote(noteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update note
         * @param {ActionModelsLibrarySBLNoteSblAppUpdateNoteSblAppUpdateNoteSblAppInput} [actionModelsLibrarySBLNoteSblAppUpdateNoteSblAppUpdateNoteSblAppInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notesAndFilesUpdateNote(actionModelsLibrarySBLNoteSblAppUpdateNoteSblAppUpdateNoteSblAppInput?: ActionModelsLibrarySBLNoteSblAppUpdateNoteSblAppUpdateNoteSblAppInput, options?: any): AxiosPromise<ActionModelsLibrarySBLNoteSblAppUpdateNoteSblAppUpdateNoteSblAppOutput> {
            return localVarFp.notesAndFilesUpdateNote(actionModelsLibrarySBLNoteSblAppUpdateNoteSblAppUpdateNoteSblAppInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload document to CRM
         * @param {string} contractId 
         * @param {string} productionOrderId 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notesAndFilesUploadCrmDocument(contractId: string, productionOrderId: string, file?: File, options?: any): AxiosPromise<GmModelLibraryModelsDocumentModel> {
            return localVarFp.notesAndFilesUploadCrmDocument(contractId, productionOrderId, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload document to Sharepoint
         * @param {string} contractId 
         * @param {string} productionOrderId 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notesAndFilesUploadSharePointDocument(contractId: string, productionOrderId: string, file?: File, options?: any): AxiosPromise<GmModelLibraryModelsDocumentModel> {
            return localVarFp.notesAndFilesUploadSharePointDocument(contractId, productionOrderId, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotesAndFilesApi - object-oriented interface
 * @export
 * @class NotesAndFilesApi
 * @extends {BaseAPI}
 */
export class NotesAndFilesApi extends BaseAPI {
    /**
     * 
     * @summary Create note
     * @param {ActionModelsLibrarySBLNoteSblAppCreateNoteSblAppCreateNoteSblAppInput} [actionModelsLibrarySBLNoteSblAppCreateNoteSblAppCreateNoteSblAppInput] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotesAndFilesApi
     */
    public notesAndFilesCreateNote(actionModelsLibrarySBLNoteSblAppCreateNoteSblAppCreateNoteSblAppInput?: ActionModelsLibrarySBLNoteSblAppCreateNoteSblAppCreateNoteSblAppInput, options?: RawAxiosRequestConfig) {
        return NotesAndFilesApiFp(this.configuration).notesAndFilesCreateNote(actionModelsLibrarySBLNoteSblAppCreateNoteSblAppCreateNoteSblAppInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete document from CRM
     * @param {string} contractId 
     * @param {string} docId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotesAndFilesApi
     */
    public notesAndFilesDeleteCrmDocument(contractId: string, docId: string, options?: RawAxiosRequestConfig) {
        return NotesAndFilesApiFp(this.configuration).notesAndFilesDeleteCrmDocument(contractId, docId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete note
     * @param {string} noteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotesAndFilesApi
     */
    public notesAndFilesDeleteNote(noteId: string, options?: RawAxiosRequestConfig) {
        return NotesAndFilesApiFp(this.configuration).notesAndFilesDeleteNote(noteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete document from Sharepoint
     * @param {string} contractId 
     * @param {string} docId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotesAndFilesApi
     */
    public notesAndFilesDeleteSharePointDocument(contractId: string, docId: string, options?: RawAxiosRequestConfig) {
        return NotesAndFilesApiFp(this.configuration).notesAndFilesDeleteSharePointDocument(contractId, docId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get document from CRM
     * @param {string} contractId 
     * @param {string} docId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotesAndFilesApi
     */
    public notesAndFilesGetCrmDocument(contractId: string, docId: string, options?: RawAxiosRequestConfig) {
        return NotesAndFilesApiFp(this.configuration).notesAndFilesGetCrmDocument(contractId, docId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get note
     * @param {string} noteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotesAndFilesApi
     */
    public notesAndFilesGetNote(noteId: string, options?: RawAxiosRequestConfig) {
        return NotesAndFilesApiFp(this.configuration).notesAndFilesGetNote(noteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all notes for the forest-manger
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotesAndFilesApi
     */
    public notesAndFilesGetNotes(options?: RawAxiosRequestConfig) {
        return NotesAndFilesApiFp(this.configuration).notesAndFilesGetNotes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List files  - Sharepoint files from contract  - CRM (Annotation) files from production-order
     * @param {string} contractId 
     * @param {string} productionOrderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotesAndFilesApi
     */
    public notesAndFilesGetProductionOrderDocuments(contractId: string, productionOrderId: string, options?: RawAxiosRequestConfig) {
        return NotesAndFilesApiFp(this.configuration).notesAndFilesGetProductionOrderDocuments(contractId, productionOrderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get document from Sharepoint
     * @param {string} contractId 
     * @param {string} docId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotesAndFilesApi
     */
    public notesAndFilesGetSharePointDocument(contractId: string, docId: string, options?: RawAxiosRequestConfig) {
        return NotesAndFilesApiFp(this.configuration).notesAndFilesGetSharePointDocument(contractId, docId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Share note with another SBL
     * @param {string} noteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotesAndFilesApi
     */
    public notesAndFilesShareNote(noteId: string, options?: RawAxiosRequestConfig) {
        return NotesAndFilesApiFp(this.configuration).notesAndFilesShareNote(noteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update note
     * @param {ActionModelsLibrarySBLNoteSblAppUpdateNoteSblAppUpdateNoteSblAppInput} [actionModelsLibrarySBLNoteSblAppUpdateNoteSblAppUpdateNoteSblAppInput] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotesAndFilesApi
     */
    public notesAndFilesUpdateNote(actionModelsLibrarySBLNoteSblAppUpdateNoteSblAppUpdateNoteSblAppInput?: ActionModelsLibrarySBLNoteSblAppUpdateNoteSblAppUpdateNoteSblAppInput, options?: RawAxiosRequestConfig) {
        return NotesAndFilesApiFp(this.configuration).notesAndFilesUpdateNote(actionModelsLibrarySBLNoteSblAppUpdateNoteSblAppUpdateNoteSblAppInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload document to CRM
     * @param {string} contractId 
     * @param {string} productionOrderId 
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotesAndFilesApi
     */
    public notesAndFilesUploadCrmDocument(contractId: string, productionOrderId: string, file?: File, options?: RawAxiosRequestConfig) {
        return NotesAndFilesApiFp(this.configuration).notesAndFilesUploadCrmDocument(contractId, productionOrderId, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload document to Sharepoint
     * @param {string} contractId 
     * @param {string} productionOrderId 
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotesAndFilesApi
     */
    public notesAndFilesUploadSharePointDocument(contractId: string, productionOrderId: string, file?: File, options?: RawAxiosRequestConfig) {
        return NotesAndFilesApiFp(this.configuration).notesAndFilesUploadSharePointDocument(contractId, productionOrderId, file, options).then((request) => request(this.axios, this.basePath));
    }
}

